body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* sticky-row 클래스 스타일 */
.sticky-row {
  background-color: #e6f7ff; /* 연한 파란색 배경 */
  font-weight: bold; /* 글씨를 굵게 */
  color: #1890ff; /* 텍스트 색상을 파란색으로 */
}

.highlight-row {
  background-color: #ffe6e6; /* Light red background */
}

.no-wrap-table .ant-table-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
